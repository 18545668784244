const sha256 = require('sha256')
const aes = require('simple-aes-256')
const Buffer = require('buffer').Buffer
const rsa = require('node-rsa')
const defaultPub = '-----BEGIN PUBLIC KEY-----' +
  'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJ94UfkNc3t0kOgT2nTR6VVrOj6BOIQ2' +
  '2WYBaxxC68R0dDadpx2XLj9Y3HZl1AjGxZCUP2luRnkm9aHyooIME7UCAwEAAQ==' +
  '-----END PUBLIC KEY-----'
const defaultSalt = 'OhMu21G4whkkt66w'
const ap = 'ONu818yRwdg/Y2STVCVxV5r69U67LwvIzXzPLx4xGkAug5mVpzqYLDiCvEfuQi4d'

const util = {
  /**
   * 获取rsa对象
   *
   * @param pub
   * @returns {NodeRSA}
   */
  getRsaKey(pub) {
    // eslint-disable-next-line new-cap
    const key = new rsa()
    key.setOptions({ encryptionScheme: 'pkcs1' })

    if (!this.isDefine(pub)) {
      key.importKey(defaultPub, 'pkcs8-public')
    } else {
      key.importKey(pub, 'pkcs8-public')
    }

    return key
  },
  /**
   * 获取随机盐
   *
   * @param salt
   * @returns {string|*}
   */
  getSalt(salt) {
    if (!this.isDefine(salt)) {
      return defaultSalt
    } else {
      return salt
    }
  },
  /**
   * 参数是否被定义
   */
  isDefine(para) {
    if (typeof para === 'undefined' || para === '' || para == null) {
      return false
    }

    return true
  },

  /**
   * 生成随机字符串，并用公钥加密
   *
   * @returns {{uid: string, env: *}}
   */
  getRand(key) {
    const uid = this.uuid(32, 16)
    const env = key.encrypt(uid, 'base64', 'utf8')
    return { uid: uid, env: env }
  },
  /**
   * 解密盐
   *
   * @param pub
   * @param salt
   * @returns {*}
   */
  decryptSalt(pub, salt) {
    return this.getRsaKey(pub).decryptPublic(salt, 'utf8')
  },

  /**
   * 获取device
   */
  getDevice() {
    let device = sessionStorage.getItem('device')
    if (!this.isDefine(device) || device.length === 0) {
      device = this.uuid(32, 16)
      sessionStorage.setItem('device', device)
    }

    return device
  },
  /**
   * 存储登录返回的token,salt,pub
   *
   * @param token
   * @param salt
   * @param pub
   */
  saveData(token, salt, pub) {
    if (!this.isDefine(token) || !this.isDefine(salt) || !this.isDefine(pub)) {
      throw new Error('web-util/saveData: Data is empty')
    }

    const result = aes.encrypt(ap, token + '|' + salt + '|' + pub).toString('hex')
    sessionStorage.setItem('data', result)
  },
  /**
   * 清空数据
   */
  clearData() {
    sessionStorage.clear()
  },

  /**
   * 判断是否已经登录
   * @returns {boolean}
   */
  isLogin() {
    try {
      const data = this.getData()
      return this.isDefine(data.token);

    } catch(error) {
      console.error(error)
      return false
    }
  },

  /**
   * 读取token,salt,pub
   *
   * @returns {*}
   */
  getData() {
    const result = sessionStorage.getItem('data')
    if (!this.isDefine(result)) {
      return { token: '', salt: defaultSalt, pub: defaultPub }
    }
    const deResult = aes.decrypt(ap, Buffer.from(result, 'hex')).toString().split('|')
    return { token: deResult[0], salt: deResult[1], pub: deResult[2] }
  },

  /**
   * 获取token
   * @returns {*}
   */
  getToken() {
    return this.getData().token
  },

  /**
   * 保存基本信息
   * @param baseInfo
   */
  saveBaseInfo(baseInfo) {
    const token = this.getToken('token')
    if (!this.isDefine(token)) {
      return
    }
    // console.log("token: ", token);
    const strBaseInfo = JSON.stringify(baseInfo)
    // console.log("strBaseInfo: ", strBaseInfo);
    const hexBaseInfo = aes.encrypt(token, strBaseInfo).toString('hex')
    // console.log("hexBaseInfo: ", hexBaseInfo);
    sessionStorage.removeItem('flash-ex-baseInfo')
    sessionStorage.setItem('flash-ex-baseInfo', hexBaseInfo)
  },

  /**
   * 获取基本信息
   * @returns {any}
   */
  getBaseInfo() {
    const token = this.getToken()
    if (!this.isDefine(token)) {
      return ''
    }
    // console.log("token: ", token);
    const str = sessionStorage.getItem('flash-ex-baseInfo')
    if (!str) {
      return ''
    }
    // console.log("str: ", str);
    const jsonStr = aes.decrypt(token, Buffer.from(str, 'hex')).toString()
    // console.log("jsonStr", jsonStr);
    return JSON.parse(jsonStr)
  },

  removeBaseInfo() {
    sessionStorage.removeItem('flash-ex-baseInfo')
  },

  /**
   * 随机生成UUID
   *
   * @param len    长度
   * @param radix  进制，例：10
   * @returns {string}
   */
  uuid(len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    let uuid = []
    let i
    radix = radix || chars.length

    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix]
    } else {
      // rfc4122, version 4 form
      var r

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-'
      uuid[14] = '4'

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16
          uuid[i] = chars[(i === 19) ? (r & 0x3) | 0x8 : r]
        }
      }
    }

    return uuid.join('')
  },

  /**
   * 根据参数对象组合生成请求参数字符串，例：type=adasdf&age=15
   *
   * @param params
   * @returns {string}
   */
  createParamStr(params) {
    const keys = Object.keys(params).sort()
    let str = ''
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i]
      str += key + '=' + params[key] + '&'
    }

    if (str.length > 0) {
      str = str.substring(0, str.length - 1)
    }

    return str
  },

  /**
   * 根据参数对象生成签名，并封装成对象
   *
   * @param params
   * @returns {*|void}
   */
  createSign(params, token, salt) {
    const nonceSrc = params.nonceSrc
    const nonce = params.nonce
    delete params.nonceSrc
    delete params.nonce

    params.timestamp = new Date().getTime()
    params.token = token
    params.device = this.getDevice()

    const str = this.createParamStr(params)
    const sign = sha256(str + nonceSrc + salt)

    params.sign = sign
    params.nonce = nonce
    delete params.token

    return params
  }
}

export default util

