import {defineStore} from "pinia";

export const walletStore = defineStore('wallet', {
	state: () => {
		return {
			curAccount: null,
		}
	},
	getters: {
		getCurAccount(state) {
			return state.curAccount
		}
	},
	actions: {
		setCurAccount(_curAccount) {
			this.curAccount = _curAccount
		}
	}
})
