import Api from '@/api/index'

export function login(loginInfo) {
  return Api.notCheck(
      '/api/login',
      'post',
      loginInfo
  )
}

export function queryIndexInfo() {
  return Api.check(
      '/api/member/index/info',
      'post'
  )
}

export function queryTeamList(data) {
  return Api.check(
      '/api/member/recommend/list',
      'post',
      data
  )
}
