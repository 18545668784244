<template>
    <div class="header-lay">
        <div class="hd-logo-lay">
            <img class="logo-ico" src="../static/images/logo-text.png" />
            <div class="menu">
                <div class="a-lay">
                    <router-link to="/" class="a-home">
                        <img src="../static/images/home.png" v-if="link" />
                        <img src="../static/images/home_s.png" v-else />
                        <span :style="!link?'color: #20EEA4':''">{{$t('tabbar.home')}}</span>
                    </router-link>
                </div>
                <div class="a-lay">
                    <router-link to="/lp">
                        <img src="../static/images/lp.png" v-if="link!=='lp'" />
                        <img src="../static/images/lp_s.png" v-else />
                        <span :style="link==='lp'?'color: #20EEA4':''">{{$t('tabbar.lp')}}</span>
                    </router-link>
                </div>
                <div class="a-lay">
                    <router-link to="/award">
                        <img src="../static/images/award.png" v-if="link!=='award'" />
                        <img src="../static/images/award_s.png" v-else />
                        <span :style="link==='award'?'color: #20EEA4':''">{{$t('tabbar.award')}}</span>
                    </router-link>
                </div>
                <div class="a-lay">
                    <router-link to="/user">
                        <img src="../static/images/user.png" v-if="link!=='user'" />
                        <img src="../static/images/user_s.png" v-else />
                        <span :style="link==='user'?'color: #20EEA4':''">{{$t('tabbar.user')}}</span>
                    </router-link>
                </div>
                <!-- <div class="a-lay">
                    <a class="a-white">
                        <img src="../static/images/whitepaper.png" />
                        <span>{{$t('tabbar.whitepaper')}}</span>
                    </a>
                    <div class="white-popup">
                        <div>{{ $t('tabbar.whitepaper') }}</div>
                        <div>Certik Audit Report</div>
                        <div>Coinscope Audit Report</div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="hd-right">
            <p></p>
            <!-- <button class="mobile-menu-icon" @click="isMobileLanguage = !isMobileLanguage">
                <img src="../static/images/mobile-menu.png" />
            </button> -->
			<button class="wallet" v-if="wStore.curAccount">{{showCurAccount}}</button>
            <button class="wallet" @click="connectWallet" v-else>{{ $t('tabbar.btn') }}</button>
            <div class="set-language">
                <img class="language-icon" @click="selectLanguage()" src="../static/images/language.png" />
                <div class="select-language" v-show="showLanguage">
                    <div :style="language=='en'?'color: white;background-color: #20EEA4':''" @click="handleLanguage('en')" >EN</div>
                    <div :style="language=='cn'?'color: white;background-color: #20EEA4':''" @click="handleLanguage('cn')" >中文</div>
                    <div :style="language=='tc'?'color: white;background-color: #20EEA4':''" @click="handleLanguage('tc')" >繁体</div>
                    <div :style="language=='jp'?'color: white;background-color: #20EEA4':''" @click="handleLanguage('jp')" >日本語</div>
                    <div :style="language=='kr'?'color: white;background-color: #20EEA4':''" @click="handleLanguage('kr')" >한국인</div>
					<div :style="language=='id'?'color: white;background-color: #20EEA4':''" @click="handleLanguage('id')" >Indonesia</div>
					<div :style="language=='vi'?'color: white;background-color: #20EEA4':''" @click="handleLanguage('vi')" >Tiếng Việt</div>
					<div :style="language=='th'?'color: white;background-color: #20EEA4':''" @click="handleLanguage('th')" >แบบไทย</div>
                </div>
                <!-- <div>
                    <span>{{ languageText }}</span>
                    <img src="../static/images/arrow-bottom.png" />
                    <select :value="language" name="lang" class="select" @change="handleLanguage">
                        <option value="en">EN</option>
                        <option value="cn">中文</option>
                        <option value="tc">繁体</option>
                        <option value="jp">日本語</option>
                        <option value="kr">한국인</option>
						<option value="id">Indonesia</option>
						<option value="vi">Tiếng Việt</option>
                    </select>
                </div> -->
            </div>

            <!-- <div class="mobile-popup" v-if="isMobileLanguage">
                <img class="language-icon" src="../static/images/language.png" />
                <div class="language">
                    {{ languageText }}
                    <img src="../static/images/arrow-bottom.png" />
                    <select :value="language" name="lang" class="select" @change="handleLanguage">
                        <option value="en">EN</option>
                        <option value="cn">中文</option>
                        <option value="tc">繁体</option>
                        <option value="jp">日本語</option>
                        <option value="kr">한국인</option>
                    </select>
                </div>
            </div> -->
        </div>
    </div>
     <!-- 绑定邀请码 -->
    <!-- <div class="invite-popup" v-if="showInviteBind">
        <div>
            <span>{{ $t('tabbar.invite') }}</span>
			<input v-if="showParentAddress" :value="showParentAddress" disabled style="color:#ffffff"/>
            <input v-else placeholder="Referral address" v-model="inputParentAddress" style="color:#ffffff"/>
            <button @click="bind">{{ $t('tabbar.binding') }}</button>
        </div>
    </div> -->
    <div class="dialog" v-if="showInviteBind">
        <div>
            <div class="title">
                {{ $t('user.bindAddress') }}
            </div>
<!--            <input :placeholder="$t('user.inputAddress')" />-->
			<input v-if="showParentAddress" :value="showParentAddress" disabled/>
			<input v-else :placeholder="$t('user.inputAddress')" v-model="inputParentAddress"/>
            <button @click="bind">{{ $t('user.bind') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'headers',
    props:{
        homeBg: {
            type: String,
            default: 'home_bg.png',
        },
        text:{
            type: String,
            default: ''
        },
    },
    data(){
        return{
            showQuit: false,
            languageList:[
                {label: 'English',value: 'en'},
                {label: '简体中文',value: 'cn'},
                {label: '繁體中文',value: 'tc'},
                {label: '日本語',value: 'jp'},
                {label: '한국인',value: 'kr'},
				{label: 'Indonesia',value: 'id'},
				{label: 'Tiếng Việt',value: 'vi'},
				{label: 'แบบไทย',value: 'th'}
            ],
            showLanguage: false,
            language: '',
            isMobileLanguage: false,
            link: '',
            linkCache: '',
            showMobileMenu: false,
            outside: false
        }
    },
    computed:{
        award(){
            if(this.link === 'invite'||this.link === 'award' || this.link == 'team' ||this.link === 'bonus' || this.link === 'record'
                    ||this.link ==='earnings'||this.link === 'plan' || this.link === 'currentStakeList' || this.link == 'transactionHistory'){
                    return true
                }else{
                    return false
                }
        },
        languageText(){
            if(this.language === 'cn'){
                return '中文'
            }else if(this.language === 'en'){
                return 'EN'
            }else if(this.language === 'tc'){
                return '繁体'
            }else if(this.language === 'jp'){
                return '日本語'
            }else if(this.language === 'kr'){
                return '한국인'
            } else if(this.language === 'id'){
				return 'Indonesia'
			} else if(this.language === 'vi'){
				return 'Tiếng Việt'
			} else if(this.language === 'th'){
				return 'แบบไทย'
			}
        }
    },
    watch:{
        $route(newval,oldval){
            this.link = newval.path.slice(1,newval.path.length);
        },
        // showLanguage(val){
        //     const html = document.getElementsByTagName('html')[0]
        //     if(val){
        //         html.style.overflow = 'hidden'
        //     }else{
        //         html.style.overflow = 'auto';
        //     }
        // }
    },
    async mounted() {
        this.link = this.$route.path.slice(1, this.$route.path.length)
		this.getLanguage()
        this.addListener();
    },
    methods:{
        addListener(){
            const _this = this;
            document.body.addEventListener('click', function () {
                _this.outside = true
            }, true)

            document.body.addEventListener('click', function () {
                if (_this.outside) {
                    _this.showLanguage = false
                }
            })
        },
        selectLanguage(){
            if(!this.showLanguage){
                this.outside = false;
            }
            this.showLanguage = !this.showLanguage
        },
        goHome(){
            this.$router.push({
                path: '/'
            })
        },
        goPage(url){
            this.$router.push({
                path: url
            })
        },
        getLanguage(){
            this.language = localStorage.getItem('locale') || 'en' ;
        },
        handleLanguage(e){
            const value = e;
            this.$i18n.locale = value
            this.showLanguage = false;
            localStorage.setItem('locale',value);
            this.language = value
        },
        mouseleave(e){
            this.linkCache = ''
        },
        mounseenter(e){
            this.linkCache = e;
        }
    }
}
</script>

<script setup>
import {call, connect, send} from "@/contract/web3-util";
import {walletStore} from "@/store/wallet";
import {memberStore} from "@/store/member";
import {webStore} from "@/store/web";
import {computed, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import Web3 from "web3";
import i18n from "@/i18n";
import {ElMessage} from "element-plus";
import {start, end} from "@/api/loading";
const wStore = walletStore()
const mStore = memberStore()
const wbStore = webStore()

const route = useRoute()

const showInviteBind = ref(false)
const inputParentAddress = ref('')

const showCurAccount = computed(() => {
	if (wStore.curAccount) {
		return wStore.curAccount.substring(0, 6) + '...' + wStore.curAccount.substring(wStore.curAccount.length - 4)
	}

	return ''
})

const showParentAddress = computed(() => {
	if (mStore.parentAddress) {
		return mStore.parentAddress.substring(0, 18) + '...' + mStore.parentAddress.substring(mStore.parentAddress.length - 12)
	}

	return ''
})

onMounted(() => {
	const params = route.query
	if (params && params.invite) {
		setInvite(params.invite)
	}
})

function connectWallet() {
	connect()
}
function setInvite(invite) {
	mStore.setParamParentAddress(invite)
	queryParent(invite)
}
function queryParent(parent) {
	call('stake', 'members', [parent]).then(function(result) {
		if (result && result.uid > 0) {
			mStore.setParentAddress(parent)
		}
	})
}

const bind = async () => {
	if (!wStore.curAccount) {
		ElMessage({
			message: i18n.global.t('alert.connectWallet'),
			type: 'warning'
		})

		return
	}

	let p
	if (!mStore.parentAddress) {
		if (!inputParentAddress.value) {
			ElMessage({
				message: i18n.global.t('alert.inputParent'),
				type: 'warning'
			})

			return
		}

		if (!Web3.utils.isAddress(inputParentAddress.value)) {
			ElMessage({
				message: i18n.global.t('alert.addressError'),
				type: 'warning'
			})

			return
		}

		const parent = await call('stake', 'members', [inputParentAddress.value])
		if (parent.uid === 0) {
			ElMessage({
				message: i18n.global.t('alert.parentError'),
				type: 'warning'
			})

			return
		}

		p = inputParentAddress.value
	} else {
		p = mStore.parentAddress
	}

	start()
	send('stake', 'bind', [p]).then(function (receipt) {
		if (receipt.status) {
			ElMessage({
				message: i18n.global.t('alert.success'),
				type: 'success'
			})

			showInviteBind.value = false
			mStore.queryMemberInfo()
		} else {
			ElMessage({
				message: i18n.global.t('alert.failed'),
				type: 'error'
			})
		}
		end()
	}).catch(function (error) {
		console.error(error)
		end()
	})
}

const mInfo = computed(()=>{
	return mStore.memberInfo
})
watch(mInfo, (newVal, oldVal) =>{
	if (newVal && newVal.uid - 0 === 0) {
		showInviteBind.value = true
	} else {
		showInviteBind.value = false
	}
})
</script>

<style scoped>
    .header-lay{
        width: calc(100% - 320px);
        height: 80px;
        padding: 0 160px;
        position: relative;
        top: 0px;
        z-index: 100;
        font-size: 18px;
        color: white;
        /* background-color: rgb(25, 27, 41); */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .hd-logo-lay{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .hd-logo-lay img{
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
    .hd-logo-lay .logo-ico{
        width: 120px;
        height: 30px;
    }
    .hd-logo-lay .a-lay{
        height: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 50px;
        white-space: nowrap;
        position: relative;
    }
    .hd-logo-lay .menu{
        height: 100%;
        display: flex;
    }
    .hd-right{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .hd-right p{
        min-width: 50px;
        margin-right: 15px;
    }
    .wallet{
        height: 37px;
        padding: 5px 20px;
        border-radius: 10px;
        font-weight: bold;
        font-size: 14px;;
        background-color: #20EEA4;
        z-index: 10;
    }
    .hd-right .set-language{
        font-size: 20px;
        margin-left: 16px;
        white-space: nowrap;
        display: flex;
    }
    .hd-right .set-language{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: 18px;
    }
    .hd-right .set-language .language-icon{
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    .hd-right img{
        width: 10px;
        height: 10px;
        margin-left: 23px
    }
    .hd-right .set-language .select{
        cursor: pointer;
        position: absolute;
        color: white;
        background-color: #222330;
        opacity: 0;
        left: 0;
        width: 100%;
        font-size: 18px;
        margin: 0;
        padding: 0;
        /* position: absolute;
        opacity: 0;
        width: 70px;
        font-size: 18px;
        margin: 0;
        padding: 0; */
    }
    .hd-logo-lay a{
        height: 100%;
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    .popup{
        cursor: pointer;
        padding: 10px 46px 10px 15px;
        color: white;
        background-color: rgb(45, 47, 61);
        position: absolute;
        top: 80px;
        display: none;
    }
    .popup:hover{
        display: block
    }
    .white-popup{
        padding: 10px 0;
        color: white;
        background-color: rgb(45, 47, 61);
        position: absolute;
        top: 80px;
        left: 0;
        display: none;
    }
    .white-popup:hover{
        display: block;
    }
    .white-popup div{
        cursor: pointer;
        padding: 8px 16px;
    }
    .white-popup div:hover{
        color: black;
        background-color: rgb(249, 235, 0);
    }
    .a-home:hover+.popup{
        display: block;
    }
    .a-white:hover+.white-popup{
        display: block;
    }
    .mobile-menu-icon{
        display: none;
        background-color: transparent;
    }
    .mobile-menu-icon img{
        width: 10px;
        height: 10px;
        margin: 0;
    }
    .mobile-popup{
        position: absolute;
        top: 80px;
        right: 25px;
        display: none;
        align-items: center;
        color: white;
        font-size: 16px;
    }
    .mobile-popup .language{
        font-size: 12px;
        margin-left: 12px;
        min-width: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mobile-popup .language .select{
        position: absolute;
        left: 0;
        opacity: 0;
        width: 50px;
        font-size: 12px;
        margin: 0;
        padding: 0;
    }
    .hd-award-lay{
        width: calc(100% - 160px);
        padding: 0 80px;
        background-color: rgb(34,35,48);
    }
    .hd-award-lay .logo-ico{
        width: 32px;
        height: 32px;
    }
    .hd-award-lay .hd-logo-lay .a-lay{
        margin-left: 0;
        margin-right: 32px;
    }
    .hd-award-lay .hd-logo-lay .a-lay .a{
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
    }
    .hd-award-right {
        display: flex;
        align-items: center;
    }
    .hd-award-right .language-lay{
        display: flex;
        align-items: center;
        margin-left: 10px;
    }
    .hd-award-right .language-icon{
        width: 15px;
        height: 15px;
    }
    .hd-award-right .language{
        font-size: 18px;
        min-width: 50px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
    }
    .hd-award-right .language img{
        width: 10px;
        height: 10px;
        margin-left: 23px;
    }
    .hd-award-right .language .select{
        cursor: pointer;
        position: absolute;
        color: white;
        background-color: #222330;
        opacity: 0;
        width: 100%;
        font-size: 18px;
        margin: 0;
        padding: 0;
    }
    .hd-award-right .login{
        margin-left: 30px;
        cursor: pointer;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }
    .hd-award-right .login img{
        width: 15px;
        margin-right: 10px;
    }
    .award-popup{
        color: white;
        background-color: rgb(25,27,40);
        z-index: 10;
        position: absolute;
        border-radius: 3px;
        padding: 5px 0;
    }
    .award-popup div{
        padding: 5px 50px 5px 10px;
    }
    .award-popup div:hover{
        color: black;
        background-color: #efe105;
    }
    .mobile-log-lay{
        display: none;
    }
    .hd-award-right .user{
        margin-left: 30px;
        height: 30px;
        padding: 0 10px;
        border: 1px solid #4d4d4d;
        display: flex;
        font-size: 12px;
        align-items: center;
        cursor: pointer;
        position: relative;
        border-radius: 2px;
    }
    .hd-award-right .user .title{
        margin: 0 10px;
    }
    .hd-award-right .user .popups{
        position: absolute;
        left: 0;
        width: 100%;
        background-color: rgb(34,35,48);
        top: 32px;
        border: 1px solid #4d4d4d;
        font-size: 16px;
    }
    .hd-award-right .user .popups div{
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .hd-award-right .user .popups div span{
        font-size: 14px;
    }
    :deep(.hd-award-right .user .popups .el-icon svg){
        width: 30px;
        height: 30px;
    }
    .invite-popup{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1010;
        background-color: rgba(0,0,0,.8);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .invite-popup div{
        max-width: 400px;
        width: calc(100% - 60px);
        padding: 40px 20px;
        border-radius: 10px;
        text-align: center;
        background-color: rgb(25, 27, 40);
    }
    .invite-popup input{
        width: calc(100% - 20px);
        height: 30px;
        outline: none;
        padding-left: 20px;
        border: 1px solid rgb(239, 225, 5);
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.12);
        margin: 20px 0 40px;
    }
    .invite-popup button{
        padding: 10px 30px;
        border-radius: 10px;
        background-color: rgb(255, 235, 0);
        font-size: 12px;
        font-weight: bold;

    }
    .select-language{
        position: absolute;
        right: 0;
        top: 40px;
        color: black;
        background-color: white;
        border-radius: 4px;
    }
    .select-language div{
        height: 25px;
        line-height: 25px;
        padding: 0 10px;
        user-select: none;
    }
    .select-language div:hover{
        color: white;
        background-color: #20EEA4;
    }
    .dialog{
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .7);
        position: fixed;
        z-index: 1010;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dialog > div{
        width: calc(100% - 100px);
        max-width: 300px;
        padding: 24px;
        background: #E9FFF7;
        box-shadow: 0px 8px 24px 0px rgba(0,0,0,0.1);
        border-radius: 24px 24px 24px 24px;
        color: black;
        display: flex;
        flex-direction: column;
    }
    .dialog img{
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
    .dialog .title{
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .dialog input{
        margin: 25px 0;
        height: 40px;
        padding: 0 8px;
        border-radius: 8px;
        border: 1px solid #CCCCCC;
    }
    .dialog button{
        height: 40px;
        font-weight: bold;
        background-color: #20EEA4;
        border-radius: 8px;
    }
    @media screen and (max-width: 900px){
        .header-lay{
            width: calc(100% - 32px);
            height: 60px;
            padding: 0 16px;
        }
        .logo-ico{
            width: 100px !important;
            height: 24px !important;
        }
        .hd-logo-lay .menu{
            display: none;
        }
        /* .hd-right .set-language{
            display: none;
        } */
        .hd-right .set-language{
            font-size: 14px;
        }
        .hd-right .set-language .language-icon{
        }
        .hd-right img{
            margin-left: 3px
        }
        .hd-right .set-language .select{
            font-size: 14px;
        }
        .hd-right p{
            display: none;
        }
        .wallet{
            height: 28px;
            font-size: 12px;
            padding: 0 10px;
            border-radius: 7px;
            white-space: nowrap;
        }
        .mobile-menu-icon{
            height: 27px;
            padding: 5px 10px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid white;
        }
        .mobile-popup{
            display: flex
        }
        .hd-award-lay{
            height: 100%;
            padding: 20px 30px;
            background-color: rgb(34,35,48);
        }
        /* .hd-award-right{
            display: none;
        } */
        .mobile-menu{
            display: flex !important;
            flex-direction: column;
        }
        .hd-award-lay .hd-logo-lay{
            display: none;
            width: 100%;
        }
        .mobile-log-lay{
            display: flex !important;
            flex-direction: column;
            align-items: flex-start;
        }
        .mobile-log-lay .menu{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .mobile-log-lay .menu .a-lay{
            margin-bottom: 10px
        }
        .logo-icon-lay{
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .mobile-right{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        .hd-award-right .language{
            font-size: 14px;
        }
        .hd-award-right .language-icon{
            margin-right: 3px;
        }
        .hd-award-right .language img{
            margin-left: 3px;
        }
    }
</style>
